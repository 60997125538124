<template>
  <div v-if="currentStep === step">
    <form @submit.prevent="handleNextStep">
      <div class="d-flex flex-column align-items-center">
        <img
          :src="getTemplatePath(step - 2)"
          alt="Plantilla seleccionada"
          class="img-thumbnail small-image mb-5"
        />
      </div>
      <div class="mb-3 d-flex justify-content-center">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            :id="'optionImage' + step"
            value="image"
            v-model="localSelectedOption"
            @change="onOptionChange"
          />
          <label class="form-check-label" :for="'optionImage' + step">Imagen</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            :id="'optionText' + step"
            value="text"
            v-model="localSelectedOption"
            @change="onOptionChange"
          />
          <label class="form-check-label" :for="'optionText' + step">Texto</label>
        </div>
      </div>

      <div v-if="localSelectedOption === 'text'" class="mb-3">
        <label for="background-color" class="form-label">Color de fondo del texto</label>
        <input
          type="color"
          id="background-color"
          class="form-control-color"
          v-model="backgroundColor"
        />
      </div>

      <div v-if="localSelectedOption === 'image'">
        <FileUploadPreview
          label="Imagen"
          :id="'image' + step"
          :file="localForm.image"
          :preview="localForm.imagePreview"
          :required="!isEditing"
          @file-change="handleFileChange"
          no-file-message="No se ha seleccionado ninguna imagen"
        />
      </div>

      <div v-else-if="localSelectedOption === 'text'">
        <div class="mb-3">
<!--           <div id="toolbar">
<select class="ql-font">
              <option selected></option>
              <option value="serif">Serif</option>
              <option value="monospace">Monospace</option>
              <option value="montserrat">Montserrat</option> 
            </select>
          </div> -->
          <label :for="'content' + step" class="form-label">Contenido</label>
          <quill-editor
            ref="quillEditor"
            v-model:content="localForm.content"
            :theme="'snow'"
            class="form-control"
            :style="{ backgroundColor: backgroundColor }"
            @input="onInputChange"
            @text-change="onTextChange"
            @ready="onEditorReady"
            toolbar="full"
             :options="editorOptions"
          ></quill-editor>
        </div>
      </div>

      <div v-if="!isLastStep" class="d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" @click="handlePrevStep">
          Anterior
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="handleNextStep"
          :disabled="isNextDisabled"
        >
          Siguiente
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, watch, computed  } from "vue";
import FileUploadPreview from "@/components/shared/FileUploadPreview.vue";
import { QuillEditor  } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    FileUploadPreview,
    QuillEditor,
  },
  props: {
    step: Number,
    currentStep: Number,
    form: Object,
    selectedOption: String,
    getTemplatePath: Function,
    isEditing: Boolean,
    steps: Array,
    selectedTemplate:Object,
  },
  emits: ["prevStep", "nextStep", "update", "validateStep"],
  setup(props, { emit }) {
    console.log("props.step",props.form)
    const localSelectedOption = ref(props.selectedOption);
    const localForm = ref({ ...props.form });
    const backgroundColor = ref("#ffffff");
    const quillEditor = ref(null); 
    const editorReady = ref(false);

/* const editorOptions = ref({
  modules: {
    toolbar: '#toolbar',
  },
}); */


    const onEditorReady = () => {
      editorReady.value = true;
      if (quillEditor.value && localForm.value.content && typeof localForm.value.content === "string") {
        const quillInstance = quillEditor.value.getQuill();
        const delta = quillInstance.clipboard.convert(localForm.value.content);
        quillInstance.setContents(delta, "api");
      }
    };

      const onTextChange = () => {
      emitUpdate("content", localForm.value.content, props.step);
    };

    watch(
      () => props.selectedTemplate,
      (newTemplate) => {
        if (newTemplate) {
          resetFormContent();
        }
      }
    );

    watch(
      () => props.form,
      (newForm) => {
        if (newForm.content && typeof newForm.content === "string") {
          localForm.value.content = newForm.content;
          localSelectedOption.value = "text";
        } else if (newForm.image) {
          localSelectedOption.value = "image";
        }
      },
      { immediate: true }
    );


    const resetFormContent = () => {
      localForm.value.content = ""; 
      localForm.value.image = null; 
    };

    const emitUpdate = (key, value) => {
      localForm.value[key] = value;
      console.log("value",value)
      emit("update", { key, value,  backgroundColor: backgroundColor.value, step: props.step });
      validateStep();
    };

    const validateStep = () => {
      const isValid =
        (localSelectedOption.value === "image" && localForm.value.image) ||
        (localSelectedOption.value === "text" && localForm.value.content);
      emit("validateStep", { step: props.step, isValid });
    };

    const extractPlainText = (content) => {
      if (!content) return "";
      if (typeof content === "string") return content;
      if (content.ops) {
        return content.ops
          .map((op) => (typeof op.insert === "string" ? op.insert : ""))
          .join("");
      }
      return "";
    };

    const onOptionChange = () => {
      if (localSelectedOption.value === "text") {
        localForm.value.image = null;
      } else if (localSelectedOption.value === "image") {
        localForm.value.content = "";
      }
      emitUpdate("selectedOption", localSelectedOption.value);
    };

    const handleNextStep = () => {
      emit("nextStep");
    };

    const handlePrevStep = () => {
      emit("prevStep");
    };

    const handleFileChange = (file) => {
      localForm.value.image = file;
      emitUpdate("image", localForm.value.image, props.step);
    };

    const onInputChange = () => {
      console.log("cambio")
      emitUpdate("content", localForm.value.content, props.step);
    };

    const isLastStep = computed(() => {
      return props.step === props.steps.length + 2;
    });

    const isNextDisabled = computed(() => {
      validateStep();

      const plainText = extractPlainText(localForm.value.content);

      if (localSelectedOption.value === "image" && !localForm.value.image) {
        return true;
      }

      if (localSelectedOption.value === "text" && !plainText.trim()) {
        return true;
      }

      return false;
    });

    return {
      localSelectedOption,
      localForm,
      emitUpdate,
      handleFileChange,
      onInputChange,
      handleNextStep,
      handlePrevStep,
      onOptionChange,
      isNextDisabled,
      backgroundColor,
      isLastStep,
      quillEditor,
      onEditorReady,
      editorReady,
      onTextChange,
   //   editorOptions,
    };
  },
};
</script>

<style scoped>
.small-image {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
}

/* Importar la fuente Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Definir la fuente Montserrat para Quill */
@font-face {
  font-family: "Montserrat";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
}

/* Aplicar la fuente Montserrat a la clase correspondiente de Quill */
::v-deep .ql-font-montserrat {
  font-family: 'Montserrat', sans-serif;
}
</style>
