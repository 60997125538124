<template>
  <!-- <nav class="main-header navbar navbar-expand navbar-white navbar-light navbar-custom"> -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="#" class="dropdown-item">
            <i class="fas fa-user mr-2"></i> My Profile
          </a>
          
          <div class="dropdown-divider"></div>
          <a href="/login" class="dropdown-item">
            <i class="fas fa-sing-out-alt mr-2"></i> Salir
          </a>
       
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavBarComponent',
};
</script>

<style>
.navbar-custom {
    background: #011f5b;
    box-shadow: 0px 3px 10px #000;
}
</style>