import { ref, computed } from 'vue';

export function usePagination() {
  const currentPage = ref(1);
  const lastPage = ref(1);

  const hasMorePages = computed(() => currentPage.value < lastPage.value);

  return {
    currentPage,
    lastPage,
    hasMorePages,
  };
}
