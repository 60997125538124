import Swal from 'sweetalert2';

export async function updateStatus(entity, updateFunction, entityType = 'elemento') {
  console.log("entity",entity)
  try {
    const newStatus = entity.status;

    const viewId = entity.view ? entity.view.id : entity.view_id;

    await updateFunction(entity.id, newStatus, viewId);
    Swal.fire({
      icon: 'success',
      title: 'Estado actualizado',
      text: `El estado del ${entityType} ha sido actualizado a ${newStatus === 2 ? 'encendido' : 'apagado'}.`,
      confirmButtonText: 'Aceptar',
    });
  } catch (error) {
    console.error(`Error actualizando el estado del ${entityType}:`, error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: `Hubo un error al actualizar el estado del ${entityType}.`,
      confirmButtonText: 'Aceptar',
    });
  }
}
