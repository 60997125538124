<template>
  <div>
    <div class="container mt-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
         <h1 class="mb-0" style="font-size: 2rem">Indices de Precio</h1>
        <div class="d-flex align-items-center mt-6 mt-md-0">
          <button @click="openModalImportar" class="btn btn-primary">Nuevo</button>
        </div>
      </div>

      <div class="mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>
      <div class="mb-3">
        <span>Página {{ currentPage }} de {{ lastPage }}</span>
      </div>
      <div v-if="loading" class="text-center">Loading...</div>
     <div v-else>
        <table
          ref="table"
          class="table table-striped table-bordered"
          style="width: 100%"
        >
          <thead class="thead-dark">
            <tr>
              <th>Tipo</th>
              <th>Fecha Creación</th>
              <th>Nombre Archivo</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="indice in filteredIndices" :key="indice.id">
              <td>{{ "Indices de Precios" }}</td>
              <td>{{ formatDate(indice.created_at) }}</td>
              <td>{{ indice.filename }}</td>
              <td class="text-center">
                <button
                  @click="deleteIndice(indice.id)"
                  class="btn btn-sm btn-outline-danger ml-2"
                >
                  <i class="fas fa-trash"></i> Eliminar
                </button> 
                <button @click="verPDF(indice)" class="btn btn-sm btn-outline-primary ml-2">
                  <i class="fas fa-file-pdf"></i> Ver PDF
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <button
            @click="fetchIndices(1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Primera
          </button>
          <button
            @click="fetchIndices(currentPage - 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Anterior
          </button>
          <button
            @click="fetchIndices(currentPage + 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="!hasMorePages"
          >
            Siguiente
          </button>
          <button
            @click="fetchIndices(lastPage)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="currentPage === lastPage"
          >
            Última
          </button>
        </div>
      </div>
    </div>
  </div>

  <ModalFormIndices
    @import-completed="fetchIndices"
    @export-completed="handleExportCompleted"
    ref="modalFormIndices"
    @closeModal="closeModal"
    :type="menuIndicesType"
  />
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalFormIndices from "@/components/indices/ModalFormIndices.vue";
import axios from "@/plugins/axios";
import Swal from 'sweetalert2';
import userConfirmation from "@/utils/userConfirmation.js";
import { getMenuType } from "@/utils/menuTypeUtils.js";

export default {
  name: "IndicesView",
  components: {
    ModalFormIndices,
  },
  data() {
    return {
      loading: false,
      error: null,
      lastPage: 1,
      currentPage: 1,
      perPage: 15,
      hasMorePages: false,
      searchTerm: "",
      indices: [],
      menuIndicesType: null,
    };
  },
  computed: {
    filteredIndices() {
      if (!this.searchTerm) return this.indices;

      const lowerCaseSearch = this.searchTerm.toLowerCase();
      return this.indices.filter(
        (indice) =>
          indice.filename.toString().toLowerCase().includes(lowerCaseSearch)
      );
    },
  },
  created() {
    this.updateMenuIndicesType();
    this.fetchIndices();
  },
  methods: {
    updateMenuIndicesType() {
       this.menuIndicesType = getMenuType("/main/indices");
    },
    async fetchIndices(page = 1) {
      this.loading = true;
      try {
        const response = await axios.get("/uploads", {
          params: {
            page: page,
            per_page: this.perPage,
            search: this.searchTerm,
            type: this.menuIndicesType || null,
          },
          withCredentials: false,
        });
        if (response.data) {
          this.indices = response.data.data;
          this.currentPage = response.data.current_page;
          this.hasMorePages = response.data.next_page_url !== null;
          this.lastPage = response.data.last_page;
        } else {
          this.error = response.data.message;
        }
      } catch (error) {
        this.error = error.response
          ? error.response.data.message
          : error.message;
      } finally {
        this.loading = false;
      }
    },
    openModalImportar() {
      this.$refs.modalFormIndices.showModal();
    },
    closeModal() {
      this.$refs.modalFormIndices.hideModal();
    },
    async handleExportCompleted() {
      await this.fetchIndices();
    },
    formatDate(dateString) {
      return this.$moment(dateString).format('DD/MM/YYYY HH:mm:ss');
    },
    verPDF(indice) {
      window.open(indice.url_pdf, '_blank');
    },
    async deleteIndice(indiceId) {
      const confirmed = await userConfirmation(`¿Está seguro de eliminar el índice de precio seleccionado?`);
      if (!confirmed) return;

      try {
        const response = await axios.delete(`uploads/delete`, {
          data: { id: indiceId }
        });

        if (response.status === 200) {
          await this.fetchIndices();
          Swal.fire({
            title: 'Indice de precio eliminado',
            text: 'El índice de precio se eliminó correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
        }
      } catch (error) {
        console.error('Error al eliminar el índice:', error);
        let errorMessage = 'Error al eliminar el índice. Por favor, intenta de nuevo más tarde.';
        
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    },
  },
};
</script>

<style scoped>
.btn-icon {
  background: none;
  border: none;
  padding: 0;
}

.btn-icon i {
  color: inherit;
  font-size: 1.2rem;
}
</style>
