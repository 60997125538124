<template>
  <div>
    <SliderComponent
      v-if="selectedType === 'slider' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

    <CardComponent
      v-if="selectedType === 'card' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

    <CardComponent
      v-if="selectedType === 'card' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />

     <SectionComponent
      v-if="selectedType === 'section' && selectedItem"
      :view="selectedItem"
      @close="emitCloseItem"
    />
  </div>
</template>

<script>
import SliderComponent from "@/components/slider/SliderComponent.vue";
import CardComponent from "@/components/card/CardComponent.vue";
import SectionComponent from "@/components/block/BlockComponent.vue";

export default {
  components: {
    SliderComponent,
    CardComponent,
    SectionComponent,
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },
  setup(_, { emit }) {
    const emitCloseItem = () => {
      emit("close-item");
    };

    return {
      emitCloseItem,
    };
  },
};
</script>
