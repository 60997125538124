import axios from '@/plugins/axios';
import Swal from 'sweetalert2';

export const fetchBlocks = async (viewId, page = 1) => {
    try {
        const response = await axios.get('/blocks', {
            params: { viewId, page  }
        });
        return response.data.data;
    } catch (error) {
        console.error('Error blocking headers:', error);
        throw error;
    }
};

export const deleteBlock = async (headerId) => {
    try {
        await axios.delete(`/blocks/${headerId}`);
    } catch (error) {
        console.error('Error deleting header:', error);
        throw error;
    }
};

export const createBlock = async (formData) => {
    try {
        filterFormData(formData);

        const response = await axios.post('/blocks', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating slider:', error);
        throw error;
    }
};

export const updateBlock = async (blockId, formData) => {
    try {
        formData.append('_method', 'PUT');
        
        filterFormData(formData);

        const response = await axios.post(`/blocks/${blockId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating slider:', error);
        throw error;
    }
};

export const updateBlockStatus = async (blockId, status) => {
    try {
        const response = await axios.put(`/blocks/${blockId}/change-status`, {
            status,
        });

        // Si la petición se ejecuta correctamente
        Swal.fire({
            icon: 'success',
            title: 'Estado actualizado',
            text: `El estado del Bloque ha sido actualizado a ${status === 2 ? 'encendido' : 'apagado'}.`,
            confirmButtonText: 'Aceptar',
        });

        return response.data;
    } catch (error) {
        // En caso de error
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Hubo un error al actualizar el estado del Bloque.',
            confirmButtonText: 'Aceptar',
        });

        console.error('Error updating block status:', error);
        throw error;
    }
};

const filterFormData = (formData) => {
    for (let key of formData.keys()) {
        const value = formData.get(key);
        if (value === null || value === "null" || value === undefined || value === '') {
            formData.set(key, "");
        }
    }
};
