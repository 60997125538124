import Swal from 'sweetalert2';

export function useNotification() {
  const handleSuccess = (message) => {
    Swal.fire({
      icon: 'success',
      title: 'Éxito',
      text: message,
      confirmButtonText: 'Aceptar'
    });
  };

  const handleError = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: message,
      confirmButtonText: 'Aceptar',
    });
  };

  return {
    handleSuccess,
    handleError,
  };
}
