import Swal from "sweetalert2";

export function showLoadingAlert(
  title = "Aguarde un instante!",
  message = "Realizando la accion solicitada..."
) {
  Swal.fire({
    title: title,
    icon: "info",
    showCloseButton: false,
    width: 700,
    html: `
      <div class="custom-spinner-container">
        <div class="spinner-border" role="status" style="margin-right: 10px;">
          <span class="sr-only">Loading...</span>
        </div>
        <span>${message}</span>
      </div>
    `,
    allowOutsideClick: false,
    showConfirmButton: false,
  });
}

export function closeLoadingAlert() {
  Swal.close();
}
