<template>
  <div class="modal fade" id="cardFormModal" tabindex="-1" aria-labelledby="cardFormModalLabel" :aria-hidden="!isModalVisible">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cardFormModalLabel">{{ isEditing ? 'Editar Card' : 'Nuevo Card' }}</h5>
          <button type="button" class="btn-close" @click="closeForm"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="name" class="form-label">Nombre</label>
              <input type="text" v-model="form.name" class="form-control" id="name" required>
            </div>
            <div class="mb-3">
              <label for="title" class="form-label">Título</label>
              <input type="text" v-model="form.title" class="form-control" id="title" required>
            </div>
            <div class="mb-3">
              <label for="content" class="form-label">Contenido</label>
              <textarea v-model="form.content" class="form-control" id="content" required></textarea>
            </div>

            <div class="mb-3 border p-3 rounded">
              <label for="type" class="form-label fw-bold">Tipo de Enlace</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="select-addon">
                  <i class="bi bi-link-45deg"></i>
                </span>
                <select v-model="form.linkType" class="form-select shadow-sm" id="type" aria-describedby="select-addon">
                  <option value="link">Enlace</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>

              <div class="mb-3" v-if="form.linkType === 'link'">
                <label for="link" class="form-label">Enlace</label>
                <input type="text" v-model="form.link" class="form-control shadow-sm" id="link" required>
              </div>

              <FileUploadPreview
                v-if="form.linkType === 'pdf'"
                label="Subir PDF"
                id="file"
                accept="application/pdf"
                :file="form.file"
                :preview="form.file ? form.file.name : ''"
                :required="!isEditing"
                @file-change="updateFile('file', $event)"
                no-file-message="No se ha seleccionado ningún archivo"
              />
            </div>

            <FileUploadPreview
              label="Imagen"
              id="image"
              accept="image/*" 
              :file="form.image"
              :preview="form.imagePreview"
              :required="!isEditing"
              @file-change="updateFile('image', $event)"
              no-file-message="No se ha seleccionado ninguna imagen"
            />

            <FileUploadPreview
              label="Icono"
              id="icon"
              accept="image/*"
              :file="form.icon"
              :preview="form.iconPreview"
              :required="!isEditing"
              @file-change="updateFile('icon', $event)"
              no-file-message="No se ha seleccionado ningún icono"
            />

            <div class="mb-3">
              <label for="order" class="form-label">Orden</label>
              <input type="number" v-model="form.order" class="form-control" id="order" required>
            </div>

            <div class="mb-3">
              <label for="color" class="form-label">Color</label>
              <input type="color" v-model="form.color" class="form-control form-control-color custom-color-input" id="color" title="Elige tu color" required>
            </div>

            <button type="submit" class="btn btn-primary">{{ isEditing ? 'Actualizar' : 'Guardar' }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import { createCard, updateCard } from '@/services/api/cardService.js'; 
import FileUploadPreview from '@/components/shared/FileUploadPreview.vue';

export default {
  components: {
    FileUploadPreview,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isModalVisible = ref(false);
    const isEditing = ref(false);
    const form = ref({
      name: '',
      title: '',
      content: '',
      link: '',
      file: null,
      image: null,
      icon: null,
      order: '',
      color: '#ffffff',
      iconPreview: '',
      imagePreview: '',
      view_id: props.view.id,
      linkType: 'link',
    });

    watch(() => props.header, (newHeader) => {
      if (newHeader) {
        isEditing.value = true;
        form.value = {
          name: newHeader.name,
          title: newHeader.title,
          content: newHeader.content,
          link: newHeader.link,
          file: newHeader.file || null,
          image: newHeader.image ? null : null,
          icon: newHeader.icon ? null : null,
          order: newHeader.order,
          color: newHeader.color || '#ffffff',
          iconPreview: newHeader.icon,
          imagePreview: newHeader.image,
          view_id: newHeader.view.id,
          linkType: newHeader.link ? 'link' : (newHeader.file ? 'pdf' : 'link'),
        };
      } else {
        isEditing.value = false;
        form.value.iconPreview = '';
        form.value.imagePreview = '';
        form.value.linkType = 'link'; 
      }
    }, { immediate: true });

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const submitForm = async () => {
      if (form.value.linkType === 'link') {
        form.value.file = null;
      } else if (form.value.linkType === 'pdf') {
        form.value.link = null;
      }

      const formData = new FormData();

      Object.keys(form.value).forEach((key) => {
        if (key === 'file' || key === 'image' || key === 'icon') {
          if (form.value[key] instanceof File) {
            formData.append(key, form.value[key]);
          }
        } else if (key !== 'imagePreview' && key !== 'iconPreview') {
          formData.append(key, form.value[key]);
        }
      });

      try {
        if (isEditing.value) {
          await updateCard(props.header.id, formData); 
          emit('success', 'Card actualizado exitosamente.');
        } else {
          await createCard(formData);  
          emit('success', 'Card creado exitosamente.');
        }

        emit('card-created');
        closeForm();
      } catch (error) {
        emit('error', error.response?.data?.message || 'Error al procesar la solicitud.');
      }
    };


    const closeForm = () => {
      isModalVisible.value = false;
      emit('close');
      const modalElement = document.getElementById('cardFormModal');
      const modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    };

    onMounted(() => {
      const modalElement = document.getElementById('cardFormModal');
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
      isModalVisible.value = true;
    });

    return {
      form,
      isEditing,
      updateFile,
      submitForm,
      closeForm,
      isModalVisible,
    };
  },
};
</script>

<style scoped>
.preview-container {
  border: 1px solid #ccc;
  min-height: 100px;
  margin: 5px;
  background-color: #305785;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-color-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 16px;
}
</style>
