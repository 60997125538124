<template>
  <div class="modal-header d-flex flex-column align-items-start">
    <h2 class="modal-title-main">{{ view.name }}</h2>
    <h5 class="modal-title-sub">{{ subtitle }}</h5>
    <button type="button" class="btn-close" @click="closeModal"></button>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  props: {
    view: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    closeModal() {
      this.$emit('close');
      const modalElement = document.getElementById(this.modalId);
      const modalInstance = Modal.getInstance(modalElement);
      modalInstance.hide();
    },
  },
};
</script>

<style scoped>
.modal-header {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-title-main {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 5px 0;
}

.modal-title-sub {
  font-size: 18px;
  font-weight: normal;
  color: #666;
  margin-bottom: 10px;
}
</style>
