export default class FileValidator {
    constructor(file, allowedExtensions = ['xlsx', 'xls']) {
      this.file = file;
      this.allowedExtensions = allowedExtensions;
    }
  
    validate() {
      if (!this.file) {
        throw new Error('Por favor, seleccione un archivo para importar.');
      }
  
      const fileExtension = this.file.name.split('.').pop().toLowerCase();
      if (!this.allowedExtensions.includes(fileExtension)) {
        throw new Error('El archivo seleccionado no es un archivo Excel válido. Por favor, seleccione un archivo con extensión valida');
      }
  
      return true;
    }
  }