export function applyResponsiveStack(tableId) {
    const table = document.getElementById(tableId);
    if (!table) return;
  
    const thElements = table.querySelectorAll('th');
    const tdElements = table.querySelectorAll('td');
  
    thElements.forEach((th, index) => {
      tdElements.forEach((td) => {
        if (td.cellIndex === index) {
          const span = document.createElement('span');
          span.classList.add('table-responsive-stack-thead');
          span.innerText = th.innerText + ': ';
          td.prepend(span);
        }
      });
    });
  
    const flexTable = () => {
      if (window.innerWidth < 768) {
        table.querySelectorAll(".table-responsive-stack-thead").forEach(el => el.style.display = 'block');
        table.querySelector('thead').style.display = 'none';
      } else {
        table.querySelectorAll(".table-responsive-stack-thead").forEach(el => el.style.display = 'none');
        table.querySelector('thead').style.display = '';
      }
    };
  
    flexTable();
    window.onresize = flexTable;
  }
  