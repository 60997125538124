<template>
  <div class="mb-3">
    <input
      type="text"
      v-model="inputValue"
      class="form-control"
      placeholder="Buscar..."
    />
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
};
</script>
