import Swal from 'sweetalert2';

export default async function userConfirmation(message = '¿Está seguro de importar el archivo seleccionado?') {
  const result = await Swal.fire({
    title: 'Confirmación',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sí',
    cancelButtonText: 'Cancelar'
  });

  return result.isConfirmed;
}
