import { createStore } from 'vuex';

export default createStore({
  state: {
    count: 0,
    app: {
      name: 'Admin ACARA',
    },
    // Inicializa el estado de cada menú como false
    menuOpenState: {

    },

    priceCategory: null,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    setPriceCategory(state, category) {
      state.priceCategory = category;
    },
    setMenuOpenState(state, { isOpen, menuKey }) {
      state.menuOpenState[menuKey] = isOpen;
    },
    setMenuIndicesType(state, type) {
      state.menuIndices.type = type;
    },
  },
  actions: {
    increment({ commit }) {
      commit('increment');
    },
    setPriceCategory({ commit }, category) {
      commit('setPriceCategory', category);
    },
    setMenuIndicesType({ commit }, type) {
      commit('setMenuIndicesType', type);
    },
  },
  modules: {},
});
