import axios from "@/plugins/axios";

export const fetchViews = async (page = 1) => {
  try {
    const response = await axios.get(`/views?page=${page}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching views: ' + error.message);
  }
};

export const deleteView = async (url) => {
  try {
    await axios.delete(`/views/${url}`);
  } catch (error) {
    throw new Error('Error deleting view: ' + error.message);
  }
};

export const createView = async (viewData) => {
  try {
    const response = await axios.post('/views', viewData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating view: ' + error.message);
  }
};

export const updateView = async (currentUrl, viewData) => {
  try {
    const response = await axios.put(`/views/${currentUrl}`, viewData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating view: ' + error.message);
  }
};
