<template>
  <div class="table-responsive" style="padding-bottom: 20px;">
    <table
      ref="table"
      :id="tableId"
      class="table table-bordered table-striped table-responsive-stack"
      style="width: 100%; min-height: 400px;"
    >
      <thead class="thead-dark">
        <tr>
          <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="view in views" :key="view.id">
          <td v-for="column in tableColumns" :key="column.key">
            {{ formatColumn(view[column.key], column.isDate) }}
          </td>
          <td class="text-center" style="position: relative;">
            <div class="btn-group btn-group-sm">
              <button
                @click="emitEditView(view)"
                class="btn btn-outline-primary"
              >
                <i class="fas fa-edit"></i> Editar
              </button>
              <button
                @click="emitDeleteView(view.url)"
                class="btn btn-outline-danger"
              >
                <i class="fas fa-trash"></i> Eliminar
              </button>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-dark"
                  @click.stop="toggleDropdown(view.id)"
                >
                  <i class="fas fa-plus"></i> Configurar
                </button>
                <div
                  v-if="dropdownVisible === view.id"
                  class="dropdown-menu show"
                  style="z-index: 1000;"
                >
                  <a
                    v-for="action in dropdownActions"
                    :key="action.label"
                    class="dropdown-item"
                    href="#"
                    @click.prevent="emitShowItem(view, action.type)"
                  >
                    {{ action.label }}
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { applyResponsiveStack } from '@/utils/responsiveTable';

export default {
  props: {
    views: {
      type: Array,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dropdownVisible = ref(null);
    const tableId = 'viewsTable';

    const toggleDropdown = (viewId) => {
      dropdownVisible.value = dropdownVisible.value === viewId ? null : viewId;
    };

    const closeDropdown = () => {
      dropdownVisible.value = null;
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const formatColumn = (value, isDate) => (isDate ? formatDate(value) : value);

    const emitEditView = (view) => emit('edit-view', view);
    const emitDeleteView = (url) => emit('delete-view', url);

    const emitShowItem = (view, type) => emit('show-item', view, type);

    const dropdownActions = [
      { label: 'Carrusel', type: 'slider' },
      { label: 'Tarjeta', type: 'card' },
      { label: 'Bloques', type: 'section' },
    ];

    const handleClickOutside = (event) => {
      if (!event.target.closest(`#${tableId}`)) {
        closeDropdown();
      }
    };

    onMounted(() => {
      applyResponsiveStack(tableId);
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      dropdownVisible,
      toggleDropdown,
      formatColumn,
      tableId,
      emitEditView,
      emitDeleteView,
      emitShowItem,
      dropdownActions,
    };
  },
};
</script>

<style scoped>
.table-responsive-stack tr {
  display: flex;
  flex-direction: row;
}

.table-responsive-stack td,
.table-responsive-stack th {
  display: block;
  flex: 1 1 0;
  box-sizing: border-box;
}

.table-responsive-stack .table-responsive-stack-thead {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    flex-direction: column;
    border-bottom: 3px solid #ccc;
    display: block;
  }
  .table-responsive-stack td {
    float: left\9;
    width: 100%;
  }
}

.btn-group-sm .btn {
  min-width: 80px;
}

@media (max-width: 768px) {
  .table {
    font-size: 14px;
  }
  .btn-group-sm .btn {
    min-width: 60px;
  }
  .btn-group-sm .dropdown-menu {
    font-size: 14px;
  }
}

.btn-group .dropdown-menu {
  position: absolute;
  transform: translate3d(0, 38px, 0); 
  top: 0;
  left: 0;
  will-change: transform;
  display: none;
  z-index: 1000;
}

.btn-group .dropdown-menu.show {
  display: block;
}

.table-responsive {
  padding-bottom: 20px; 
}
</style>
