<template>
  <div>
    <h1>HOLA</h1>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const state = ref('');

    return {
      state,
    };
  },
};
</script>

<style scoped>

</style>
