<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="/" class="brand-link">
      <img
        src="/img/logo.png"
        :alt="$store.state.app.name"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light">{{ $store.state.app.name }}</span>
    </a>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="/adminlte/dist/img/user2-160x160.jpg"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block">Gaston Loisa</a>
        </div>
      </div>

      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <nav-item-component
            v-for="(item, index) in sidebarMenuItemsService"
            :key="index"
            :icon="item.icon"
            :link="item.link"
            :subMenu="item.subMenu"
          >
            {{ item.label }}
          </nav-item-component>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import NavItemComponent from './NavItemComponent.vue';
import { sidebarMenuItemsService } from '@/services/api/sidebarMenuItemsService.js';

export default {
  components: {
    NavItemComponent,
  },
  data() {
    return {
      sidebarMenuItemsService,
    };
  },
};
</script>
